<template>
    <div class="p-fluid">
        <div class="field">
            <label for="car_nombre">Plan Académico</label>
            <InputText id="pla_codigo" v-model="selectedPlan.pla_nombre" disabled  class="opacity-100"/>
        </div>
    </div>
    <Divider align="left" class="bg-white mb-0 pb-0">
        <div class="flex-inline align-item-center">
            <i class="pi pi-angle-double-right mr-2"></i>
            <b>Áreas de Formación</b>
        </div>
    </Divider>
    <div class="flex justify-content-end my-1">
        <Button type="button" icon="pi pi-plus-circle" label="Nuevo" class="mx-2 p-button-text  w-8rem" @click="upsertArea_onClick()" v-if="!viewForm" />
    </div>

    <div class="p-fluid  border-round  p-3   border-dashed-custom  border-gray-400" v-if="viewForm"> 
        <div class="field">
            <label for="afp_nombr">Nombre</label>
            <InputText id="pla_codigo" v-model="selectedAreaFormacion.paf_area_form" :class="{'p-invalid': submitted && !selectedAreaFormacion.paf_area_form}"/>
            <small id="paf_area_form" class="p-error" v-if="submitted && !selectedAreaFormacion.paf_area_form">Área requerido.</small>
        </div>

        <div class="field">
            <label for="afp_competencia">Competencia</label>
            <Textarea id="afp_competencia" v-model.trim="selectedAreaFormacion.paf_competencia" required="true" rows="10" cols="20" 
            :autoResize="true"  placeholder="Definir competencia de carrera"  :class="{'p-invalid': submitted && !selectedAreaFormacion.paf_competencia}"/>
            <small id="paf_competencia" class="p-error" v-if="submitted && !selectedAreaFormacion.paf_competencia">Descriptor requerido.</small>
        </div>

        <div class="flex justify-content-end my-2">
            <div class="flex-initial mx-1"><Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancel_onClick()"/></div>
            <div class="flex-initial mx-1"><Button label="Guardar" icon="pi pi-check" class="" @click="saveArea_onClick()" /></div>
        </div>
    </div>
    <div class="flex flex-column" v-else>
        <Panel :header="area.paf_area_form" :toggleable="true" :collapsed="true" class="mb-1" v-for="area in areaFormacionList" :key="area">
            <template #icons>
                <button class="p-panel-header-icon p-link mr-2" @click="upsertArea_onClick(area, true)">
                    <span class="pi pi-pencil text-primary"></span>
                </button>
            </template>
            <p class="text-justify">{{area.paf_competencia}}</p>
            <Divider type="dashed" class="p-divider-dashed  p-divider-muted p-0 m-0"/>
            <div class="flex justify-content-end">
                <a href="javascript:void(0)"  class="p-button p-button-text text-danger w-8rem" @click="deleteArea_onClick(area)"> <i class="pi pi-times mr-3"/> Eliminar </a>
            </div>
        </Panel>
        
    </div>
</template>


<script>
export default {
    emits: ["update_onEmit"],
    props:{
        Service : null,
        PlanAcademico :{
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            selectedPlan :  this.PlanAcademico,
            areaFormacionList : [],
            selectedAreaFormacion :  {
                paf_codigo : 0,
                paf_area_form : "",
                paf_competencia : "",
                paf_codpla : 0
            },
            viewForm  :  false,
            submitted :  false
        }
    },

    async mounted(){
        await this.fx_onLoad();
    },
    methods:{
        async fx_onLoad(){
            this.Service.getAreaFormacion(this.selectedPlan.pla_codigo).then(res  => this.areaFormacionList = res)
        },
        upsertArea_onClick(dataValue =  null, editMode = false){
            this.viewForm =  true;

            if(!editMode){
                this.selectedAreaFormacion  = this.selectedAreaFormacion =   {
                    paf_codigo : 0,
                    paf_area_form : "",
                    paf_competencia : "",
                    paf_codpla : 0
                }
            }else{
                this.selectedAreaFormacion =  {...dataValue}
            }
        },
        cancel_onClick(){
            this.viewForm =  false;
        },
        async saveArea_onClick(){
            try{
                this.submitted = true;
                if(!this.selectedAreaFormacion.paf_area_form || !this.selectedAreaFormacion.paf_area_form.trim() 
                    || !this.selectedAreaFormacion.paf_competencia || !this.selectedAreaFormacion.paf_competencia.trim()){
                    return;
                }

                var data = {
                    area_formacion : this.selectedAreaFormacion.paf_area_form,
                    competencia : this.selectedAreaFormacion.paf_competencia
                }
                if(this.selectedAreaFormacion.paf_codigo == 0){
                    await this.Service.createPlanAcademico(this.selectedPlan.pla_codigo, data).then(result => this.selectedCarrera =  result)
                    this.$toast.add({severity:'success', summary: '¡Registrado!', detail:'Área de formación registrada con éxito', life: 1500});
                }else{
                    await this.Service.updatePlanAcademico(this.selectedAreaFormacion.paf_codigo, data).then(result => this.selectedCarrera =  result)
                    this.$toast.add({severity:'success', summary: '¡Actualizado!', detail:'Área de formación actualizada con éxito', life: 1500});
                }
                this.viewForm =  false;
                await this.fx_onLoad();

                this.$emit("update_onEmit")
            }catch(ex){
                this.$catchError(ex);
            }
        },

        async deleteArea_onClick(area){
            var message = "";
            this.$confirm.require({
                message: `¿Seguro que quieres eliminiar esta área: ${area.paf_area_form} ?`,
                header: 'Eliminar Área',
                icon: 'pi pi-info-circle',
                acceptLabel : "Sí, eliminar.",
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.Service.deleteAreaFormacion(area.paf_codigo).then(res  => {
                        message = res
                        this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                        var idx =  this.areaFormacionList.findIndex(x=> x.paf_codigo ==  area.paf_codigo);
                        this.areaFormacionList.splice(idx, 1);
                    }).catch(ex => {
                        this.$toast.add({severity:'error', summary:'¡Ocurrió un error!', detail:ex, life: 3000});    
                    })
                },
            });
        },
        
    }
}
</script>


<style scope>
    .p-panel-header{
        background:none !important
    }
</style>
